.profile_tools {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile_tools li {
  list-style-type: none;
}

@media (min-width:667px) {
  .profile_tools .small_screen {
    display: none;
  }
}
